import Vue from 'vue'
import IdleVue from 'idle-vue'
import store from '@state/store'

const options = {
  idleTime: 3600000,
  store,
  startAtIdle: false,
}

Vue.use(IdleVue, options)
