import apiClient from '@/client/utils/server-api-client'

export const state = {}

export const mutations = {}

export const actions = {
  async download({ rootState }, { candidateId, fileId }) {
    const response = await apiClient({
      url: `/api/v1/companies/${rootState.auth.currentUser.companyId}/candidates/${candidateId}/objects/${fileId}/download`,
      method: 'GET',
      responseType: 'blob',
    })

    const fileName = response.headers['content-disposition']
      .split('filename=')[1]
      .split(';')[0]

    const file = window.URL.createObjectURL(new Blob([response.data]))

    const docUrl = document.createElement('a')
    docUrl.href = file
    docUrl.target = '_blank'
    docUrl.setAttribute('download', fileName)
    document.body.appendChild(docUrl)

    docUrl.click()
  },
}
