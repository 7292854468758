export const state = {
  campaignData: {
    source: null, // Identify the advertiser, site, publication, etc. that is sending traffic to your property.
    medium: null, // The advertising or marketing medium, for example: cpc, banner, email newsletter.
    campaign: null, // The individual campaign name, oneLiner, promo code, etc. for a product.
    content: null, // Used to differentiate similar content, or links within the same ad. For example, if you have two call-to-action links within the same email message, you can use utm_content and set different values for each so you can tell which version is more effective.
  },
}

export const mutations = {
  SET_AD_CAMPAIGN(state, campaignData) {
    state.campaignData = campaignData
  },
}

export const actions = {
  async setAdCampaign({ commit }, campaignData) {
    commit('SET_AD_CAMPAIGN', campaignData)
  },
}
