import axios from 'axios'
import { getAuth } from 'firebase/auth'

const instance = axios.create()

// Configure authorisation
instance.interceptors.request.use(async function (config) {
  // eslint-disable-next-line dot-notation
  config.headers['Authorization'] = getAuth().currentUser
    ? `Bearer ${await getAuth().currentUser.getIdToken()}`
    : ''

  return config
})

instance.defaults.headers.post['Content-Type'] = 'application/json'

export default instance
