import appConfig from '@client/app.config'
const isToLocaleSupported = toLocaleDateStringSupportsLocales()

function toLocaleDateStringSupportsLocales() {
  try {
    new Date().toLocaleDateString('i')
  } catch (e) {
    return e.name === 'RangeError'
  }
  return false
}

export function formatDate(date, options, locale) {
  if (date == null) return null

  if (isToLocaleSupported) {
    return date.toLocaleDateString(locale || appConfig.forcedLocale, options)
  }

  return date
}

export function formatDateTime(date, options, locale) {
  if (date == null) return null

  if (isToLocaleSupported) {
    const usedLocale = locale || appConfig.forcedLocale

    return date
      .toLocaleDateString(usedLocale, options)
      .concat(' ', date.toLocaleTimeString(usedLocale, options))
  }

  return date
}
