import apiClient from '@/client/utils/server-api-client'

export const state = {}

export const mutations = {
  SET_DEFAULT_STATUS(state, newValue) {
    state.defaultStatus = newValue
  },
}

export const actions = {
  async fetchByJobId({ commit, rootState }, { jobId }) {
    let candidates

    try {
      candidates = (
        await apiClient.get(
          `/api/v1/companies/${rootState.auth.currentUser.companyId}/jobs/${jobId}/candidates`
        )
      ).data

      candidates.forEach((candidate) => {
        candidate.createdAt = new Date(candidate.createdAt)
        candidate.modifiedAt = new Date(candidate.modifiedAt)
      })
    } catch (error) {
      if (error.response.status !== 404) throw error

      candidates = []
    }

    return candidates
  },
  async get({ rootState }, { jobId, candidateId }) {
    const data = (
      await apiClient.get(
        `/api/v1/companies/${rootState.auth.currentUser.companyId}/jobs/${jobId}/candidates/${candidateId}`
      )
    ).data

    data.createdAt = new Date(data.createdAt)
    data.modifiedAt = new Date(data.createdAt)

    return data
  },
  async create({ rootState }, { jobId, data }) {
    return (
      await apiClient.post(
        `/api/v1/companies/${rootState.auth.currentUser.companyId}/jobs/${jobId}/candidates`,
        data
      )
    ).data
  },
  async update({ rootState }, { jobId, candidateId, data }) {
    return await apiClient.patch(
      `/api/v1/companies/${rootState.auth.currentUser.companyId}/jobs/${jobId}/candidates/${candidateId}`,
      data
    )
  },
  async search({ rootState }, term) {
    return (
      await apiClient.get(
        `/api/v1/companies/${rootState.auth.currentUser.companyId}/jobs/candidates/search`,
        { params: { name: term } }
      )
    ).data
  },
  setDefaultStatus({ commit }, status) {
    commit('SET_DEFAULT_STATUS', status)
  },
}
