import apiClient from '@/client/utils/server-api-client'

export const state = {}

export const mutations = {}

export const actions = {
  async getProductPrices({ rootState }) {
    const data = (
      await apiClient.get(
        `/api/v1/companies/${rootState.auth.currentUser.companyId}/products/prices`
      )
    ).data

    return data
  },
}
