import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import colors from 'vuetify/lib/util/colors'
import AppLogo from '@components/common/icons/app-logo-icon.vue'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'mdi',
    values: {
      'app-logo': {
        component: AppLogo,
        name: 'app-logo',
      },
    },
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#22c55e',
        primaryDark: '#009331',
        primaryLight: '#bbf7d0',
        secondary: colors.blueGrey.darken3,
        titleColor: colors.blueGrey.darken4,
        backgroundGrey: colors.grey.lighten3,
        borderGrey: colors.grey,
        accent: colors.green.accent4,
        accentDarken: '#00ac39',
        accentIndigo: colors.indigo.accent3,
        accentPink: colors.pink.accent3,
        accentDeepPurple: colors.deepPurple.accent3,
        accentLightBlue: colors.lightBlue.accent3,
        backgroundPink: colors.pink.accent2,
        backgroundIndigo: colors.indigo.accent2,
        backgroundDeepPurple: colors.deepPurple.accent2,
        backgroundLightBlue: colors.lightBlue.accent2,
        error: colors.red.accent2,
      },
      dark: {
        primary: colors.green,
        secondary: colors.green.darken3,
        accent: colors.green.accent4,
      },
    },
  },
}

export default new Vuetify(opts)
