<script>
import MainLayout from '@/client/router/layouts/main-layout.vue'

export default {
  page: {
    title: 'Loading page...',
    meta: [{ name: 'description', content: 'Loading page...' }],
  },
  components: { MainLayout },
}
</script>

<template>
  <MainLayout gradient-background>
    <v-row align="center" justify="center" class="fill-height">
      <v-progress-circular
        :size="150"
        :width="10"
        color="white"
        indeterminate
      />
    </v-row>
  </MainLayout>
</template>
