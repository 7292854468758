import apiClient from '@/client/utils/server-api-client'

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async create({ rootState, dispatch }, data) {
    await apiClient.post(
      `/api/v1/companies/${rootState.auth.currentUser.companyId}/invitations`,
      data
    )

    await dispatch('users/fetchAll', false, { root: true })
  },
  async get({ commit }, { companyId, invitationId }) {
    const invitation = (
      await apiClient.get(
        `/api/v1/companies/${companyId}/invitations/${invitationId}`
      )
    ).data

    return invitation
  },
}
