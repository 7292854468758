import apiClient from '@/client/utils/server-api-client'

export const state = {}

export const mutations = {}

export const actions = {
  async get({ commit }, { companySlug, jobId }) {
    const jobListing = (
      await apiClient.get(`/api/v1/job-listings/${companySlug}/jobs/${jobId}`)
    ).data

    return jobListing
  },
  async getAll({ commit }, { companySlug }) {
    const jobListings = (
      await apiClient.get(`/api/v1/job-listings/${companySlug}`)
    ).data

    return jobListings
  },
  async apply({ commit }, { companySlug, jobId, data }) {
    return (
      await apiClient.post(
        `/api/v1/job-listings/${companySlug}/jobs/${jobId}/application`,
        data
      )
    ).data
  },
  async uploadResume({ commit }, { companySlug, jobId, candidateId, file }) {
    const formData = new FormData()

    formData.append('file', file)

    await apiClient.post(
      `/api/v1/job-listings/${companySlug}/jobs/${jobId}/application/${candidateId}/cover-letter`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  },
}
