import _ from 'lodash'
import apiClient from '@/client/utils/server-api-client'

export const state = {
  isLoaded: false,
  company: {
    id: null,
    name: null,
    oneLiner: null,
    billing: {},
    currency: {
      symbol: null,
      code: null,
    },
  },
}

export const getters = {
  isServicePlanOrTrialActive(state) {
    return (
      state.company.billing.trialExpiresAt > new Date() ||
      _.get(state.company.billing, 'plan.active', false)
    )
  },
}

export const mutations = {
  SET_IS_LOADED(state, value) {
    state.isLoaded = value
  },
  SET_COMPANY(state, company) {
    state.company = company
  },
  UPDATE_COMPANY(state, update) {
    state.company = _.merge(state.company, update)
  },
}

export const actions = {
  async fetchCompany(
    { commit, state, rootState },
    { companyId, useCache = true } = { useCache: true }
  ) {
    if (state.isLoaded && useCache) return state.company

    const companyResponse = (
      await apiClient.get(
        `/api/v1/companies/${companyId || rootState.auth.currentUser.companyId}`
      )
    ).data

    companyResponse.createdAt = new Date(companyResponse.createdAt)

    companyResponse.billing.currentPeriodStart &&= new Date(
      companyResponse.billing.currentPeriodStart
    )
    companyResponse.billing.currentPeriodEnd &&= new Date(
      companyResponse.billing.currentPeriodEnd
    )
    companyResponse.billing.trialExpiresAt &&= new Date(
      companyResponse.billing.trialExpiresAt
    )

    commit('SET_COMPANY', companyResponse)
    commit('SET_IS_LOADED', true)

    return companyResponse
  },
  async update({ commit, rootState }, data) {
    await apiClient.patch(
      `/api/v1/companies/${rootState.auth.currentUser.companyId}`,
      data
    )
    commit('UPDATE_COMPANY', data)
  },
  async uploadLogo({ rootState }, { file }) {
    const formData = new FormData()

    formData.append('file', file)

    await apiClient.post(
      `/api/v1/companies/${rootState.auth.currentUser.companyId}/logo`,
      formData
    )
  },
}
