import apiClient from '@/client/utils/server-api-client'

export const state = {}

export const mutations = {}

export const actions = {
  async getAllByCandidate({ rootState }, { jobId, candidateId }) {
    const documents = (
      await apiClient.get(
        `/api/v1/companies/${rootState.auth.currentUser.companyId}/jobs/${jobId}/candidates/${candidateId}/documents`
      )
    ).data

    documents.forEach(formatData)

    return documents
  },

  async download({ rootState }, { jobId, candidateId, documentId }) {
    const response = await apiClient({
      url: `/api/v1/companies/${rootState.auth.currentUser.companyId}/jobs/${jobId}/candidates/${candidateId}/documents/${documentId}/download`,
      method: 'GET',
      responseType: 'blob',
    })

    const fileName = decodeURIComponent(
      response.headers['content-disposition'].match(/filename="?([^"]+)"?/)[1]
    )

    const file = window.URL.createObjectURL(new Blob([response.data]))

    const docUrl = document.createElement('a')
    docUrl.href = file
    docUrl.target = '_blank'
    docUrl.setAttribute('download', fileName)
    document.body.appendChild(docUrl)

    docUrl.click()
  },
}

function formatData(data) {
  data.createdAt = new Date(data.createdAt)
  data.modifiedAt = new Date(data.createdAt)
}
