<script>
export default {
  page: {
    title: '404',
    meta: [{ name: 'description', content: '404' }],
  },
  props: {
    resource: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <v-container class="fill-height gradient" fluid max-width="400px">
    <v-row align="center" justify="center">
      <v-col cols="12" class="text-center">
        <v-icon color="secondary" :size="$vuetify.breakpoint.mobile ? 160 : 250"
          >mdi-face-agent</v-icon
        >
        <p class="secondary--text text-h4 white--text font-weight-bold"
          >Code 404</p
        >
        <div class="white--text text-subtitle-1"
          >We searched the page everywhere, but couldn't find it. Over and
          Out.</div
        >
      </v-col>
    </v-row>
    <v-row align="end">
      <v-col cols="12" class="text-center">
        <v-btn outlined rounded to="/" color="white" class="text-none">
          Back home
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.gradient {
  background-image: linear-gradient(
    70deg,
    var(--v-primary-base),
    var(--v-accent-base)
  );
}
</style>
