import _ from 'lodash'
import apiClient from '@/client/utils/server-api-client'

export const state = {
  isLoaded: false,
  users: [],
}

export const getters = {}

export const mutations = {
  SET_IS_LOADED(state, value) {
    state.isLoaded = value
  },
  SET_USERS(state, users) {
    state.users = users
  },
  ADD_PERSON(state, person) {
    state.users.push(person)
  },
  UPDATE_PERSON(state, { id, data }) {
    const index = state.users.findIndex((person) => person.id === id)
    state.users[index] = _.merge(state.users[index], data)
  },
  REMOVE_PERSON(state, { id }) {
    const index = state.users.findIndex((person) => person.id === id)

    state.users.splice(index, 1)
  },
}

export const actions = {
  async create({ rootState, commit }, data) {
    const person = (
      await apiClient.post(
        `/api/v1/companies/${rootState.auth.currentUser.companyId}/users`,
        data
      )
    ).data

    commit('ADD_PERSON', person)
  },
  async update({ rootState, commit, dispatch }, { id, data }) {
    await apiClient.patch(
      `/api/v1/companies/${rootState.auth.currentUser.companyId}/users/${id}`,
      data
    )

    commit('UPDATE_PERSON', { id, data })

    await dispatch('company/fetchCompany', { useCache: false }, { root: true })
  },
  async remove({ rootState, commit }, { id }) {
    await apiClient.delete(
      `/api/v1/companies/${rootState.auth.currentUser.companyId}/users/${id}`
    )

    commit('REMOVE_PERSON', { id })
  },
  async fetchAll({ commit, state, rootState }, useCache = true) {
    if (state.isLoaded && useCache) return state.users

    const users = (
      await apiClient.get(
        `/api/v1/companies/${rootState.auth.currentUser.companyId}/users`
      )
    ).data

    commit('SET_USERS', users)
    commit('SET_IS_LOADED', true)
  },
  async search({ rootState }) {
    return (
      await apiClient.get(
        `/api/v1/companies/${rootState.auth.currentUser.companyId}/users/search`
      )
    ).data
  },
}
