<script>
export default {
  name: 'AppLogoIcon',
}
</script>

<template>
  <svg
    width="23.095mm"
    height="23.095mm"
    version="1.1"
    viewBox="0 0 23.095 23.095"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        id="linearGradient1756"
        x1="102.37"
        x2="102.62"
        y1="130.96"
        y2="108.03"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#13c07b" offset="0" />
        <stop stop-color="#00c853" offset="1" />
      </linearGradient>
    </defs>
    <g transform="translate(-90.189 -108.04)">
      <rect
        x="90.404"
        y="108.25"
        width="22.665"
        height="22.665"
        rx="5.3663"
        fill="url(#linearGradient1756)"
      />
      <path
        class="cls-173"
        d="m106.42 116.06a2.6035 2.6035 0 1 0-2.6035-2.6035 2.6061 2.6061 0 0 0 2.6035 2.6035zm-2.1855-2.6035a2.1855 2.1855 0 1 1 2.1855 2.1855 2.1881 2.1881 0 0 1-2.1855-2.1855z"
        fill="#fff"
        stroke="#fff"
        stroke-width=".9"
      />
      <path
        class="cls-173"
        d="m106.36 127.61c-1.6457 0-2.667-0.93662-2.667-2.4448v-1.8388h-4.0931v1.8388c0 1.5081-1.0239 2.4448-2.6696 2.4448-1.6457 0-2.667-0.93662-2.667-1.9209v-11.673c0-2.032 1.0213-2.9686 2.667-2.9686 1.6457 0 2.6696 0.93663 2.6696 2.4448v5.1726h4.0984c0.18256-1.3229 1.1721-2.1167 2.6617-2.1167 1.6457 0 2.667 0.97102 2.667 2.4765v6.141c0 1.5081-1.0213 2.4448-2.667 2.4448zm-7.1438-4.6805h4.8869v2.2357c0 1.2832 0.84667 2.0479 2.2701 2.0479 1.4235 0 2.2701-0.76465 2.2701-2.0479v-6.141c0-1.2806-0.84931-2.0796-2.2701-2.0796-1.4208 0-2.2304 0.82814-2.2701 2.0717v0.045h-4.9001v-5.5695c0-1.2806-0.84931-2.0479-2.2728-2.0479-1.4235 0-2.2701 0.76729-2.2701 2.5718v11.673c0 0.75935 0.84931 1.524 2.2701 1.524 1.4208 0 2.2728-0.76465 2.2728-2.0479z"
        fill="#fff"
        stroke="#fff"
        stroke-width=".9"
      />
    </g>
  </svg>
</template>
