import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { initializeApp } from 'firebase/app'

// Initialize Firebase client SDK
const app = initializeApp({
  apiKey: window.$config.FIREBASE_API_CLIENT_KEY,
  authDomain: window.$config.FIREBASE_AUTH_DOMAIN,
})

const auth = getAuth(app)

export { auth, onAuthStateChanged }
