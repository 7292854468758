<script>
// TODO: Move all main components to main.vue
import appConfig from '@client/app.config'

// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js'
// eslint-disable-next-line no-unused-vars
import ChartDataLabels from 'chartjs-plugin-datalabels'

export default {
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return title ? `${title} - ${appConfig.title}` : appConfig.title
    },
  },
}
</script>

<template>
  <div id="app">
    <v-app>
      <!-- Sizes your content based upon application components -->
      <v-main>
        <!-- If using vue-router -->
        <RouterView :key="$route.fullPath" />
      </v-main>
    </v-app>
  </div>
</template>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

// Following classes are necessary for Lexical editor

.lexical-editor {
  font-size: medium;

  .editor-und {
    text-decoration: underline;
  }

  .editor-text-strikethrough {
    text-decoration: line-through;
  }

  .editor-undStrike {
    text-decoration: underline line-through;
  }

  .editor-h1 {
    line-height: 4rem;
  }

  .editor-h2 {
    line-height: 3rem;
  }

  .editor-h3 {
    line-height: 2rem;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    ul,
    ol {
      padding-left: 0.5rem !important;
    }
  }

  .editor-p {
    margin-bottom: 1.5rem !important;
    line-height: 1.5rem;
  }

  .editor-ol,
  .editor-ul {
    margin-bottom: 1.5rem !important;
    line-height: 1.5rem;
    margin-left: 1rem;
  }

  .editor-li {
    line-height: 1.5rem;
  }

  .email-p,
  .email-ol,
  .email-ul,
  .email-li {
    margin-bottom: 4px !important;
  }
}

// Following classes are necessary for quill-js editor

.ql-editor {
  min-height: 150px;
  font-size: large;

  h1 {
    margin-bottom: 0.75rem;
    line-height: 3rem;
  }
  h2 {
    margin-bottom: 0.75rem;
    line-height: 2rem;
  }
  h3 {
    margin-bottom: 0.75rem;
    line-height: 1.8rem;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    ul,
    ol {
      padding-left: 0.5rem !important;
    }
  }

  p,
  ol,
  ul {
    margin-bottom: 16px !important;
    line-height: 1.5rem;
  }
  li {
    line-height: 1.5rem;
  }
  ul {
    margin-left: 1rem;
    list-style: disc;
  }
  ol {
    margin-left: 1rem;
    list-style: decimal;
  }
}

// Following classes are necessary for flowchart editor
.dragging {
  z-index: 111 !important;
}
.block {
  position: absolute;
  z-index: 9;
}
.indicator {
  position: absolute;
  z-index: 2;
  width: 12px;
  height: 12px;
  margin-top: -5px;
  background-color: #217ce8;
  border-radius: 60px;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.05, 0.03, 0.35, 1);
  transform: scale(1);
}
.invisible {
  opacity: 0 !important;
  transform: scale(0);
}
.indicator::after {
  display: block;
  width: 12px;
  height: 12px;
  content: '';
  background-color: #217ce8;
  border-radius: 60px;
  opacity: 0.2;
  transform: scale(1.7);
}
.arrowblock {
  position: absolute;
  overflow: visible;
  pointer-events: none;
}
.arrowblock svg {
  width: -webkit-fill-available;
  overflow: visible;
}
</style>
