import apiClient from '@/client/utils/server-api-client'

export const state = {}

export const mutations = {}

export const actions = {
  async getAll({ rootState }, { jobId, candidateId, pageToken, pageSize }) {
    let response

    try {
      response = (
        await apiClient.get(
          `/api/v1/companies/${rootState.auth.currentUser.companyId}/jobs/${jobId}/candidates/${candidateId}/emails`,
          {
            params: {
              pageToken,
              pageSize,
            },
          }
        )
      ).data

      response.results.forEach(formatData)
    } catch (error) {
      if (error.response.status !== 404) throw error

      response = {
        results: [],
      }
    }

    return response
  },
  async create({ rootState }, { jobId, candidateId, data }) {
    const email = (
      await apiClient.post(
        `/api/v1/companies/${rootState.auth.currentUser.companyId}/jobs/${jobId}/candidates/${candidateId}/emails`,
        data
      )
    ).data

    formatData(email)

    return email
  },
}

function formatData(data) {
  data.createdAt = new Date(data.createdAt)
}
