import Vue from 'vue'
import router from '@router'
import store from '@state/store'
import App from './app.vue'
import vuetify from './plugins/vuetify'
import { auth, onAuthStateChanged } from './plugins/firebase'
import './plugins/idle-vue'
import './plugins/vue-draggable'

// Globally register all `_base`-prefixed components
import '@components/_globals'

// Register global filters for reusability
import './filters'

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

let app

onAuthStateChanged(auth, (user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app')

    // If running e2e tests...
    if (process.env.VUE_APP_TEST === 'e2e') {
      // Attach the app to the window, which can be useful
      // for manually setting state in Cypress commands
      // such as `cy.logIn()`.
      window.__app__ = app
    }
  }
})
