import humanize from 'humanize-string'

export function humanizeString(string) {
  return humanize(string)
}

export function capitalizeFirstChar(string) {
  return string
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : ''
}
