import apiClient from '@/client/utils/server-api-client'

export const state = {}

export const mutations = {}

export const actions = {
  async createPortalSession({ rootState }, { customerId }) {
    const data = (
      await apiClient.post(
        `/api/v1/companies/${rootState.auth.currentUser.companyId}/billing/portal`,
        { customerId }
      )
    ).data

    return data
  },
}
