<script>
export default {
  props: {
    gradientBackground: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: 'backgroundGrey',
    },
    columnBackground: {
      type: String,
      default: null,
    },
  },
}
</script>

<template>
  <v-container
    fluid
    fill-height
    :class="`align-start ${gradientBackground ? 'gradient' : backgroundColor} ${
      columnBackground && 'py-0'
    }`"
  >
    <v-row justify="center" class="fill-height">
      <v-col cols="12" xl="10" :class="columnBackground"><slot></slot> </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.gradient {
  background-color: var(--v-primary-base);
  background-image: radial-gradient(
    at 33% 130%,
    var(--v-primary-lighten3),
    transparent 50%
  );
}
</style>
