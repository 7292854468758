import apiClient from '@/client/utils/server-api-client'

export const state = {}

export const mutations = {}

export const actions = {
  // eslint-disable-next-line no-empty-pattern
  async createCheckoutSession({ rootState }, { priceId }) {
    const data = (
      await apiClient.post(
        `/api/v1/companies/${rootState.auth.currentUser.companyId}/checkout`,
        { priceId }
      )
    ).data

    return data
  },
}
