import appConfig from '@client/app.config'
const isToLocaleSupported = toLocaleStringSupportsLocales()

function toLocaleStringSupportsLocales() {
  return !!(
    typeof Intl === 'object' &&
    Intl &&
    typeof Intl.NumberFormat === 'function'
  )
}

export function formatCurrency(
  number,
  currency,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2
) {
  if (number == null || currency == null) return null

  if (isToLocaleSupported) {
    return number.toLocaleString(appConfig.forcedLocale, {
      style: 'currency',
      currency,
      minimumFractionDigits,
      maximumFractionDigits,
    })
  }

  return `${number.toFixed(maximumFractionDigits)} ${currency}`
}

export function formatNumber(
  number,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2
) {
  if (number == null) return null

  if (isToLocaleSupported) {
    return number.toLocaleString(appConfig.forcedLocale, {
      minimumFractionDigits,
      maximumFractionDigits,
    })
  }

  return number.toFixed(maximumFractionDigits)
}

export function formatPercent(
  number,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2
) {
  if (number == null) return null

  if (isToLocaleSupported) {
    return number.toLocaleString(appConfig.forcedLocale, {
      style: 'percent',
      minimumFractionDigits,
      maximumFractionDigits,
    })
  }

  return `${number.toFixed(maximumFractionDigits)}%`
}
