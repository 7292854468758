// Permissions

// Self
const VIEW_SELF = 'self.view'

// Users
const VIEW_USERS = 'users.view'
const CREATE_USERS = 'users.create'
const EDIT_USERS = 'users.edit'
const DELETE_USERS = 'users.delete'
const MANAGE_USERS = 'users.*'

// Company
const VIEW_COMPANY = 'company.view'
const EDIT_COMPANY = 'company.edit'
const MANAGE_COMPANY = 'company.*'

// Jobs
const VIEW_JOBS = 'jobs.view'
const VIEW_RELATED_JOBS = 'jobs.view_related' // Users can view jobs created or watched by them
const CREATE_JOBS = 'jobs.create'
const EDIT_JOBS = 'jobs.edit'
const DELETE_JOBS = 'jobs.delete'
const MANAGE_JOBS = 'jobs.*'

// Billing
const MANAGE_BILLING = 'billing.*'

// Roles
const roleEnums = Object.freeze({
  ADMIN: 'ADMIN',
  RECRUITMENT_ADMIN: 'RECRUITMENT_ADMIN',
  RECRUITMENT_LEAD: 'RECRUITMENT_LEAD',
  TEAM_LEAD: 'TEAM_LEAD',
})

const roles = Object.values(roleEnums)

const roleDefinitions = Object.freeze([
  {
    value: roleEnums.ADMIN,
    text: 'Administrator',
    description: 'Can access and manage everything without restrictions.',
  },
  {
    value: roleEnums.RECRUITMENT_ADMIN,
    text: 'Recruitment Admin',
    description:
      'Can manage all jobs, candidates and company branding, but not invite new users or manage billing.',
  },
  {
    value: roleEnums.RECRUITMENT_LEAD,
    text: 'Recruitment Lead',
    description:
      'Can manage jobs and candidates created by themselves or where they have been added as watchers.',
  },
  {
    value: roleEnums.TEAM_LEAD,
    text: 'Team Lead',
    description: `Can view jobs and candidates where they have been added as watchers.`,
  },
])

// Roles and respective Permissions
const rolePermissions = new Map(
  Object.entries({
    ADMIN: [
      VIEW_SELF,
      MANAGE_USERS,
      MANAGE_COMPANY,
      MANAGE_JOBS,
      MANAGE_BILLING,
    ],
    RECRUITMENT_ADMIN: [VIEW_SELF, VIEW_USERS, MANAGE_COMPANY, MANAGE_JOBS],
    RECRUITMENT_LEAD: [VIEW_SELF, CREATE_JOBS, EDIT_JOBS, VIEW_RELATED_JOBS],
    TEAM_LEAD: [VIEW_SELF, VIEW_RELATED_JOBS],
  })
)

// Helpers
function hasPermission(userPermissions, permission) {
  // Check if the exact permission exists
  if (userPermissions.includes(permission)) {
    return true
  }

  // Check for wildcard permissions
  const [permissionCategory] = permission.split('.')

  const wildcardPermission = `${permissionCategory}.*`

  if (userPermissions.includes(wildcardPermission)) {
    return true
  }

  return false
}

function hasAnyPermission(userPermissions, permissionList) {
  return permissionList.some((permission) =>
    hasPermission(userPermissions, permission)
  )
}

function getRoleDefinition(role) {
  return roleDefinitions.find((roleDefinition) => roleDefinition.value === role)
}

module.exports = {
  VIEW_SELF,
  CREATE_USERS,
  VIEW_USERS,
  EDIT_USERS,
  DELETE_USERS,
  MANAGE_USERS,
  VIEW_COMPANY,
  EDIT_COMPANY,
  MANAGE_COMPANY,
  CREATE_JOBS,
  EDIT_JOBS,
  VIEW_JOBS,
  VIEW_RELATED_JOBS,
  DELETE_JOBS,
  MANAGE_JOBS,
  MANAGE_BILLING,
  ...roleEnums,
  getRoleDefinition,
  roles,
  roleDefinitions,
  rolePermissions,
  hasAnyPermission,
  hasPermission,
}
