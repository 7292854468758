import Vue from 'vue'
import { formatCurrency, formatNumber, formatPercent } from './number-formatter'
import { formatDate, formatDateTime } from './date-formatter'
import * as stringFormatter from './string-formatter'

Vue.filter('currency', formatCurrency)
Vue.filter('number', formatNumber)
Vue.filter('percent', formatPercent)
Vue.filter('date', formatDate)
Vue.filter('datetime', formatDateTime)
Vue.filter('humanizeString', stringFormatter.humanizeString)
Vue.filter('capitalizeFirstChar', stringFormatter.capitalizeFirstChar)
