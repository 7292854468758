import apiClient from '@/client/utils/server-api-client'

export const state = {}

export const mutations = {}

export const actions = {
  async getAll({ rootState }, { jobId, candidateId }) {
    let notes

    try {
      notes = (
        await apiClient.get(
          `/api/v1/companies/${rootState.auth.currentUser.companyId}/jobs/${jobId}/candidates/${candidateId}/notes`
        )
      ).data

      notes.forEach(formatData)
    } catch (error) {
      if (error.response.status !== 404) throw error

      notes = []
    }

    return notes
  },
  async create({ rootState }, { jobId, candidateId, data }) {
    const note = await apiClient.post(
      `/api/v1/companies/${rootState.auth.currentUser.companyId}/jobs/${jobId}/candidates/${candidateId}/notes`,
      data
    )

    formatData(note)

    return note
  },
}

function formatData(data) {
  data.createdAt = new Date(data.createdAt)
  data.modifiedAt = new Date(data.createdAt)
}
